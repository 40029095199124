.toast-error-container {
  background-color: #ED1C24 !important;
  margin: 0;
  min-height: fit-content;
  padding: 0;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.Toastify__progress-bar--error {
  background-color: #8e0606 !important;
}

.toast-success-container {
  background-color: #00C572 !important;
  margin: 0;
  min-height: fit-content;
  padding: 0;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.Toastify__progress-bar--success {
  background-color: #028951 !important;
}

