#root, html, body {
  height: 100%;
  font-family: 'Nunito Sans', sans-serif;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.wrapper-save-cancel {
  button {
    min-width: 135px;
  }
}

.word-break-word {
  word-break: break-word;
}

.text-trucne-pre-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}