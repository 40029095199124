.text-white-important {
  color: #ffffff !important;
}

.button-dropdown-single {
  padding: 5px 8px !important;
}

.button-reset {
  border-top: 1px solid #F4F4F4;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-menu-button {
  width: max-content;
  display: flex;
}