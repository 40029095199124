.custom-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  div.rc-table-container {
    flex: 1;
    position: relative;
    .rc-table-content {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      overflow: auto;
    }
  }
  table {
    width: 100%;
    // border-top-right-radius: 8px;
    // overflow: hidden;
    thead {
      // border-top-left-radius: 8px;
      // border-top-right-radius: 8px;
      background-color: #F4F4F4;
      text-align: left;
      position: sticky;
      top: 0;
      tr {
        th {
          background-color: #F4F4F4;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 10px;
          padding-right: 10px;
          &:first-child {
            border-top-left-radius: 8px;
            padding-left: 20px;
            padding-bottom: 25px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }
    }
    tbody {
      tr {
          border-bottom: 1px solid #AAAAAA;
        td {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 14px;
          padding-bottom: 14px;
          &:nth-child(1) {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.custom-pagination-dropdown {
  border: 1px solid #AAAAAA !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  div > button {
    padding: 5px 5px;
    color: #000000;
    font-weight: 400;
    img {
      margin-left: 5px;
    }
  }
}

.custom-prev-page-button {
  background-color: transparent !important;
  padding: 6px 7px !important;
  border-radius: 0px !important;
  img {
    width: 13px;
    transform: rotate(90deg);
  }
}

.custom-next-page-button {
  background-color: transparent !important;
  padding: 6px 7px !important;
  border-radius: 0px !important;
  img {
    width: 13px;
    transform: rotate(-90deg);
  }
}

.custom-action-button-table {
  background-color: transparent !important;
  border-radius: 0px;
  div {
    button {
      padding: 5px 5px;
      img {
        margin-left: 5px;
      }
    }
    div {
      width: max-content;
    }
  }
}

.rc-table-placeholder {
  text-align: center;
}