.react-datepicker,
.react-datepicker__month-container {
    width: 100%;
    border: none;
    font-family: Roboto, Arial, Helvetica, sans-serif;
}
 
.react-datepicker__header  {
  background-color: #FFFFFF;
  border-bottom: none;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}  

.rotate-180 {
  transform: rotate(180deg);
}

.react-datepicker__year-wrapper {
  justify-content: center;
  gap: 18px;
  max-width: none;
}

.react-datepicker__year-text:hover {
  background-color: #2BBECB;
  color: #FFFFFF;
}
.react-datepicker__month-wrapper {
  display: flex;
  gap: 60px;
}
.react-datepicker__year-text, .react-datepicker__month-text {
  font-size: 24px;
  width: 65px !important;
  height: 43px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex !important;
  border-radius: 6px;
  color: #000000;
}

.react-datepicker__year-text--today, .react-datepicker__month-text--today {
  background-color: #2bbecb7e;
  color: #FFFFFF;
  font-weight: normal;
  &:hover {
    background-color: #2bbecb7e;
  }
}

.react-datepicker__year-text--selected, .react-datepicker__month-text--keyboard-selected {
  background-color: #2BBECB;
  color: #FFFFFF;
  font-weight: normal;
  &:hover {
    background-color: #278e97;
  }
}

.react-datepicker__month.react-datepicker__monthPicker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.react-datepicker__month--disabled {
  color: #cfcfcf !important;
}