.financial-calculation-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  div.rc-table-container {
    flex: 1;
    position: relative;
    .rc-table-content {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      overflow: auto !important;
    }
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    // border-top-right-radius: 8px;
    // overflow: hidden;
    thead {
      // border-top-left-radius: 8px;
      // border-top-right-radius: 8px;
      // background-color: #F4F4F4;
      text-align: left;
      position: sticky;
      top: 0;
      z-index: 2;
      tr {
        th {
          background-color: #c3c2c2;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          // &:first-child {
          //   border-top-left-radius: 8px;
          //   padding-left: 20px;
          //   padding-bottom: 0px;
          // }
          // &:last-child {
          //   border-top-right-radius: 8px;
          // }
        }
      }
      .rc-table-cell {
        font-size: 15px;
      }
    }
    tbody {
      tr {
          // border-bottom: 1px solid #AAAAAA;
        td {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 14px;
          padding-bottom: 14px;
          &:nth-child(1) {
            padding-left: 20px;
          }
        }
      }
    }
  }
  .bordered-th {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 3px solid white !important;
    border-bottom: 0px !important;
  }
  .bordered-left {
    border-left: 3px solid white !important;
  }
  .bordered-right {
    border-right: 3px solid white !important;
  }
  .bg-gray-old {
    background-color: #e6e6e6 !important;
  }
  .bordered-top {
    border-top: 3px solid white !important;
  }
  .bordered-top-left {
    border-top-left-radius: 8px;
    border-top: 3px solid white !important;
    border-left: 3px solid white !important;
  }
  .bordered-top-right {
    border-top-right-radius: 8px;
    border-top: 3px solid white !important;
    border-right: 3px solid white !important;
  }
}
  
.rc-table-row-expand-icon-cell {
  width: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 3px solid white !important;
  border-bottom: 0px !important;
}

// .rc-table-cell.rc-table-cell-row-hover {
//   background: rgba(255,0,0,0.05);
// }
// .rc-table-ping-left .rc-table-cell-fix-left-first::after,
// .rc-table-ping-left .rc-table-cell-fix-left-last::after {
//   box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.183);
// }
// .rc-table-ping-right .rc-table-cell-fix-right-first::after,
// .rc-table-ping-right .rc-table-cell-fix-right-last::after {
//   box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.183);
// }
  
.financial-calculation-table thead td,
.financial-calculation-table thead th {
  text-align: center;
  background: #f7f7f7;
}
.financial-calculation-table thead .rc-table-cell-scrollbar::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  width: 1px;
  background: #f7f7f7;
  content: '';
}
.rc-table-rtl.financial-calculation-table thead .rc-table-cell-scrollbar::after {
  right: -1px;
  left: auto;
}
 
.rc-table-placeholder {
  text-align: center;
}
.financial-calculation-table tbody tr td,
.financial-calculation-table tbody tr th {
  background: #fff;
}
 
 
.rc-table-fixed-column .rc-table-body::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: '';
}
.rc-table-expanded-row .rc-table-cell {
  box-shadow: inset 0 8px 8px -8px green;
}
.rc-table-expanded-row-fixed {
  box-sizing: border-box;
  margin: -16px -8px;
  margin-right: -10px;
  padding: 16px 8px;
}
.rc-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  width: 0;
  content: '';
}
.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #aaa;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid currentColor;
  cursor: pointer;
}
.rc-table-row-expand-icon.rc-table-row-expanded::after {
  content: '-';
}
.rc-table-row-expand-icon.rc-table-row-collapsed::after {
  content: '+';
}
.rc-table-row-expand-icon.rc-table-row-spaced {
  visibility: hidden;
}
.rc-table-title {
  padding: 16px 8px;
  border-bottom: 0;
}
.rc-table-footer {
  padding: 16px 8px;
  border-top: 0;
}
.financial-calculation-table tfoot td {
  background: #fff;
}
.rc-table-sticky-holder {
  position: sticky;
  z-index: 2;
}
.rc-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  opacity: 0.6;
  transition: transform 0.1s ease-in 0s;
}
.rc-table-sticky-scroll:hover {
  transform: scaleY(1.2);
  transform-origin: center bottom;
}
.rc-table-sticky-scroll-bar {
  height: 8px;
  background-color: #bbb;
  border-radius: 4px;
}
.rc-table-sticky-scroll-bar:hover {
  background-color: #999;
}
.rc-table-sticky-scroll-bar-active {
  background-color: #999;
}

.custom-action-button-table {
  background-color: transparent !important;
  border-radius: 0px;
  div {
    button {
      padding: 5px 5px;
      img {
        margin-left: 5px;
      }
    }
    div {
      width: max-content;
    }
  }
}

.th-px-3 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.th-p-0 {
  padding: 0!important;
}

.text-right-force {
  text-align: right !important;
}