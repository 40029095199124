.react-datepicker,
.react-datepicker__month-container {
    width: 100%;
    border: none;
    font-family: Roboto, Arial, Helvetica, sans-serif;
}

.react-datepicker__day,
.react-datepicker__day-name { 
  margin-left: 11px;
  margin-right: 11px;
}

.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected { 
  background-color: #D5FAFD;
  color: #000000;
  border-radius: 50%;
  &:hover {
    background-color: #2BBECB;
    border-radius: 50%;
  }
}

.react-datepicker__day--in-selecting-range {
  background-color: #D5FAFD !important;
  color: #000000 !important;
  border-radius: 50% !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  background-color: #2BBECB !important;
  font-weight: normal;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--selected:hover {
  background-color: #2BBECB !important;
}

.react-datepicker__header  {
  background-color: #FFFFFF;
  border-bottom: none;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.react-datepicker__navigation {
  margin-top: 7px;
}

.react-datepicker__day--outside-month {
  color: #959494;
  opacity: 0;
  pointer-events: none;
}

.rotate-180 {
  transform: rotate(180deg);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../../../assets/image/icon/cheveron-down-aqua.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 20px;
  border-radius: 2px;
  padding: 10px !important;
  padding-right: 2rem !important;
  margin-right: 10px !important;
  &:focus-visible {
    outline: none;
    border: 1px solid gray;
    border-radius: 8px;
  }
}